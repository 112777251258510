<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                        <h3 class='mb-1'>Cari Ekle</h3>
                        <div class='text-xl'>{{ activeProjectName }}</div>
                    </div>
                </div>


                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label for='officialName' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Ünvanı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='name' v-model='dataItem.name' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='officialName' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Resmi Ünvanı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='officialName' v-model='dataItem.officialName' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='officialAddress' class='col-12 mb-2 md:col-2 md:mb-0'>Fatura Adresi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='officialAddress' v-model='dataItem.officialAddress' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='officialPostalCode' class='col-12 mb-2 md:col-2 md:mb-0'>Posta Kodu</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='officialPostalCode' v-model='dataItem.officialPostalCode' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='city' class='col-12 mb-2 md:col-2 md:mb-0'>İl<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='city' v-model='dataItem.officialCityId' :options='cityList' optionLabel='name'
                                      option-value='id' placeholder='Seçim Yapın' @change='cityChanged'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.officialCityId > 0'>
                        <label for='town' class='col-12 mb-2 md:col-2 md:mb-0'>İlçe<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='town' v-model='dataItem.officialTownId' :options='townList' optionLabel='name'
                                      option-value='id' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='taxOffice' class='col-12 mb-2 md:col-2 md:mb-0'>Vergi Dairesi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='taxOffice' v-model='dataItem.taxOffice' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='taxNumber' class='col-12 mb-2 md:col-2 md:mb-0'>Vergi Numarası<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='taxNumber' v-model='dataItem.taxNumber' type='text' maxlength />
                        </div>
                    </div>


                    <div class='field grid mb-5'>
                        <label for='phone' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Telefon</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='phone' type='text' v-model='dataItem.phone' maxlength='10' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='email' class='col-12 mb-2 md:col-2 md:mb-0'>Firma E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='email' type='text' v-model='dataItem.email' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personName' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili Adı, Soyadı</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personName' type='text' v-model='dataItem.personName' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personPhone' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili GSM</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personPhone' type='text' maxlength='10' v-model='dataItem.personPhone' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personEmail' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personEmail' type='text' v-model='dataItem.personEmail' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='description' class='col-12 mb-2 md:col-2 md:mb-0'>Açıklama</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='description' v-model='dataItem.description' type='text' />
                        </div>
                    </div>
                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Firma Tipi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='gender' autocomplete='off' v-model='dataItem.type' :options='companyTypes'
                                      optionLabel='name' option-value='value' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>
                    <div class='field grid mb-5'>
                        <label for='isActive' class='col-12 mb-2 md:col-2 md:mb-0'>Durum</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isActive' v-model='dataItem.isActive' />
                        </div>
                    </div>


                    <div class='field grid'>
                        <label for='saveButton' class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2' @click='save'
                                    v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import { getActiveProjectName } from '../common/commonConstantFunctions';
import AddressService from '../../services/addressService';
import CompanyService from '../../services/companyService';
import { getCompanyTypes } from '../common/commonFunctions';

export default {
    components: { AdminToast },
    _companyService: null,
    _addressService: null,
    async created() {
        this._addressService = new AddressService();
        this._companyService = new CompanyService();
        this.activeProjectName = getActiveProjectName();
        await this.fillDropDowns(this.dataItem.officialCityId);

    },
    async mounted() {
        this.companyTypes = await getCompanyTypes();
    },
    data() {
        return {
            isProcessing: false,
            activeProjectName: '',
            companyTypes: [],
            dataItem: {
                name: '',
                isActive: true,
                insertDate: '',
                phone: '',
                email: '',
                personName: '',
                personPhone: '',
                personEmail: '',
                officialName: '',
                officialAddress: '',
                officialPostalCode: '',
                officialCityId: '',
                officialTownId: '',
                taxNumber: '',
                taxOffice: '',
                eInvoiceAddress: '',
                passiveReason: '',
                passiveDate: '',
                description: '',
            },
            cityList: [],
            townList: [],
            visibleLeft: false,
        };
    },
    methods: {
        async save() {
            this.isProcessing = true;
            if (this.validateForm()) {

                let checkResponse = await this._companyService.checkCompanyExists(this.dataItem.taxNumber);
                if (checkResponse.isSuccess) {
                    if (checkResponse.data.length > 0) {
                        this.isProcessing = false;
                        showValidationMessage(this, 'Bu vergi numarası ile kayıtlı bir firma zaten var');
                        return;
                    }
                } else {
                    this.isProcessing = false;
                    showValidationMessage(this, 'Firma Kontrolünde Hata Oluştu');
                    return;
                }
                const insertResponse = await this._companyService.insertCompany(this.dataItem);
                if (insertResponse.isSuccess) {
                    showSuccessMessage(this, 'Ekleme işlemi başarılı');
                    setTimeout(async () => await this.$router.push({
                        name: 'editCompany',
                        params: { companyId: insertResponse.data },
                    }), 2000);
                } else {
                    showErrorMessage(this, 'Firma Eklemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        validateForm() {
            if (this.dataItem.name == '') {
                showValidationMessage(this, 'Firma Adı Alanı Boş Bırakılamaz');
                return false;
            }
            if (this.dataItem.officialName == '') {
                showValidationMessage(this, 'Firma Resmi Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.officialAddress == '') {
                showValidationMessage(this, 'Firma Adres Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.officialCityId == '') {
                showValidationMessage(this, 'Firma Şehir Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.officialTownId == '') {
                showValidationMessage(this, 'Firma İlçe Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.taxNumber == '') {
                showValidationMessage(this, 'Firma Vergi Numarası Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.taxOffice == '') {
                showValidationMessage(this, 'Firma Vergi Dairesi Alanı Boş Bırakılamaz');
                return false;
            }


            if (this.dataItem.type == null || this.dataItem.type == '') {
                showValidationMessage(this, 'Firma Tipi Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

        async fillDropDowns(cityId) {
            let cityResponse = await this._addressService.getCities(90);
            if (cityResponse.isSuccess) {
                this.cityList = cityResponse.data;
            }
            if (cityId != null && cityId > 0) {
                let townResponse = await this._addressService.getTowns(cityId);
                if (townResponse.isSuccess) {
                    this.townList = townResponse.data;
                }
            }

        },
        async cityChanged(event) {
            let townResponse = await this._addressService.getTowns(event.value);
            if (townResponse.isSuccess) {
                this.townList = townResponse.data;
            }
        },
    },
};

</script>


<style scoped>

</style>
